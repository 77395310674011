<template>
  <div v-if="userDetail" class="wrap">
    <div class="The_header">
      <ul>
        <li class="left">
          <router-link to="/home">
            <i class="iconfont icon-fanhui"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.grab") }}</li>
        <li class="right">
          <router-link to="/profile">
            <i class="iconfonticon-wode"></i>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="main main_page main_grab" v-if="orderTotal">
      <div class="rob_one">
        <div class="one_box">
          <ol>
            <li>
              <i>{{ t("profile.label.grabbedToday") }}</i>
              <span>{{ orderTotal.todayTo }}</span>
            </li>
            <li>
              <i>{{ t("profile.label.availableToday") }}</i>
              <span>
                {{ orderTotal.availableToday }}
              </span>
            </li>
            <li>
              <i>{{ t("profile.label.walletBalance") }}</i>
              <span>{{ formatMoney(+userDetail.amount) }}</span>
            </li>
            <li>
              <i>{{ t("profile.label.commissionYesterday") }}</i>
              <span>
                {{ formatMoney(+orderTotal.commissionYesterday) }}
              </span>
            </li>
            <li>
              <i>{{ t("profile.label.commissionsTotal") }}</i>
              <span>{{ formatMoney(+orderTotal.commissionTotal) }}</span>
            </li>
            <li>
              <i>{{ t("profile.label.commissionsToday") }}</i>
              <span>{{ formatMoney(+orderTotal.commissionToday) }}</span>
            </li>
          </ol>
          <div class="one_box_div">
            <ul>
              <h3>
                <span>{{ orderTotal.productManageVo?.name }}</span>
                <b>{{
                  formatMoney(orderTotal.productManageVo?.largestAmount)
                }}</b>
              </h3>
              <li>
                <b>
                  {{
                  
                  formatPercentage(
                    orderTotal.productManageVo?.amount 
                    
                  )
                }}</b>
                {{ t("profile.label.commission") }}
                <span :class="`level level_${level}`">
                  <i>{{ t("profile.label.vip") }}{{ level }}</i>
                </span>
              </li>
            </ul>
            <div class="one_btn">
              <p>
                <button type="button" @click="handleAutomaticOrder">
                  {{ t("profile.label.automaticOrder") }}
                </button>
              </p>
              <p>
                <!-- <button type="button" @click="confirmVipUpgrade">
                  {{ t("profile.label.vipLevelUpgrade") }}
                </button> -->
              </p>
              <p>
                <router-link to="/grab_order">
                  {{ t("profile.label.orderRecord") }}
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="rob_two">
        <div class="two_box">
          <div class="title">
            <i></i>
            {{ t("profile.label.explanation") }}
          </div>
          <div class="two_text text-pre-line">
            {{ t("common.text.grabExplanation") }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <Loading :loading="loading" />
  <AModal
    v-model:visible="automaticVisible"
    centered
    :footer="null"
    :maskClosable="false"
    wrapClassName="Sys_Popup_loading"
    width="85%"
  >
    <div class="icon">
      <p class="loading"></p>
    </div>
    <p class="p_text" v-html="tip"></p>
  </AModal>

  <AModal
    v-model:visible="visible"
    centered
    :title="result?.success ? t('common.grabStatus.SUCCESS') : t('common.grabStatus.FAIL')"
    :okText="t('common.button.confirm')"
    :cancelText="t('common.button.cancel')"
    @ok="handleGrab"
    wrapClassName="Sys_Popup Sys_Popup_rob"
    width="85%"
  >
    <div v-if="result" class="Rob_ok">
      <ul>
        <li>
          {{ t("profile.label.time") }}:
          <b>{{ formatServerDateTime(result.data?.createDate) }}</b>
        </li>
        <li>
          {{ t("profile.label.serialNumber") }}:
          <b>{{ result.data?.orderNo }}</b>
        </li>
      </ul>
      <ol>
        <h2>
          <img
            :src="sysDataDictionaryWbObj.TXHQDZ?.dataContent + result.data?.img"
          />
        </h2>
        <h3>
          {{ result.data?.productSubsetName }}
        </h3>
        <li>
          <span>{{ formatMoney(result.data?.unitPrice) }}</span>
          <span class="r">x{{ result.data?.orderCnt }}</span>
        </li>
      </ol>
      <ul>
        <li>
          {{ t("profile.label.total") }}
          <span>{{ formatMoney(result.data?.orderAmount) }}</span>
        </li>
        <li>
          {{ t("profile.label.commission") }}
          <span>{{ formatMoney(result.data?.profit) }}</span>
        </li>
        <li>
          {{ t("profile.label.return") }}
          <span class="c-red">
            {{ formatMoney(+result.data?.orderAmountTotal) }}
          </span>
        </li>
      </ul>
    </div>
  </AModal>

  <AModal
    :footer="null"
    v-model:visible="upgradeVisible"
    @cancel="closeUpgrade"
    title="Vip Level Upgrade"
    wrapClassName="Popup_bottom"
    width="100%"
  >
    <div
      v-if="vipPrivileges && userDetail"
      class="Popup_bottom_box Popup_bottom_vip"
    >
      <ol>
        <label
          v-for="(vip, index) in vipPrivileges"
          :key="index + ',' + vip.userRank?.slice(3)"
        >
          <li v-if="+vip.userRank?.slice(3) > 1">
            <label :for="`checkbox-1-${index + 1}`">
              <input
                v-model="upgradeModel.level"
                :value="+vip.userRank?.slice(3)"
                type="radio"
                name="checkbox-level"
                :id="`checkbox-1-${index + 1}`"
                :disabled="
                  +vip.userRank?.slice(3) <= +userDetail.levelName?.slice(3)
                "
              />
              <p>
                {{ t("profile.label.vip") }} {{ +vip.userRank?.slice(3) }}
                <span>{{ formatMoney(vip.sellPrice) }}</span>
              </p>
            </label>
          </li>
        </label>
      </ol>
    </div>
    <div class="Sys_submit_btn confirm">
      <button
        @click="handleVipUpgrade"
        :disabled="upgradeModel.level == 0"
        type="button"
      >
        {{ t("common.button.confirm") }}
      </button>
    </div>
  </AModal>
</template>
<script>
import { ref, watch, reactive, onMounted } from "vue";
import {
  formatMoney,
  formatPercentage,
  formatServerDateTime,
} from "@/utils/formatter";
import { message } from "ant-design-vue";
import { useI18n } from "@/lang";
import { useRoute } from "vue-router";
import { userDetail } from "@/hooks/userDetail";
import { grabId } from "@/hooks/grabId";
import api from "@/api";
import { sysDataDictionaryWbObj } from "@/hooks/sysDataDictionaryWbObj";
import Loading from "@/components/Loading.vue";
export default {
  name: "Home",
  components: {
    Loading,
  },
  setup() {
    onMounted(() => {
      api.orderTotal({ productId: grabId.value }).then((res) => {
        if (res?.data?.success) {
          orderTotal.value = res.data.data;
        }
      });
      api.levelConfigGetAll().then((res) => {
        if (res?.data?.success) {
          vipPrivileges.value = res.data?.data;
        }
      });
    });
    const { t } = useI18n();
    const orderTotal = ref(null);
    const loading = ref(false);
    let visible = ref(false);
    let upgradeVisible = ref(false);
    let automaticVisible = ref(false);
    let result = ref(null);
    const vipPrivileges = ref(null);
    const userBalance = {};
    const userData = {};
    const canReq = ref(true);
    const upgradeModel = reactive({ level: 0 });

    const {
      params: { level },
    } = useRoute();

    console.log(level);
    const vipPrivilege = { title: "" };
    const currentPrivilege = {};

    let index = ref(0);
    let tip = ref(t("profile.hits.grabTip1"));
    let tips = [
      t("profile.hits.grabTip1"),
      t("profile.hits.grabTip2"),
      t("profile.hits.grabTip3"),
      "",
    ];

    watch(index, (value) => {
      if (value === 4) {
        automaticVisible.value = false;
        if (result && result.value) {
          visible.value = true;
        }
      }
    });

    async function handleAutomaticOrder() {
      if (!canReq.value) {
        return;
      }
      canReq.value = false;
      index.value = 0;
      result.value = null;
      tip.value = t("profile.hits.grabTip1");
      try {
        if (!(+orderTotal.value?.availableToday > 0)) {
          message.error(t("error.grab.finishTasks"));
          return;
        }
        if (
          +userDetail.value?.amount <
          orderTotal.value?.productManageVo?.largestAmount
        ) {
          message.error(t("error.grab.insufficient"));
          return;
        }
        loading.value = true
        let res = await api.orderDetail({ productId: grabId.value });
        loading.value = false
        result.value = res?.data;
        if (!res?.data?.success) {
          setTimeout(() => {
            visible.value = false;
          });
        } else {
          automaticVisible.value = true;
          let i = 0;
          for (let item of tips) {
            i++;
            setTimeout(() => {
              tip.value = item;
              index.value++;
            }, 300 + i * 500 + Math.round(Math.random() * 1000));
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        canReq.value = true;
        loading.value = false
      }
    }

    async function handleGrab() {
      automaticVisible.value = false;
      visible.value = false;
      // 调接口
      try {
        loading.value = true;
        let res = await api.orderSave({
          orderAmountTotal: result.value.data?.orderAmountTotal,
          orderCnt: result.value.data?.orderCnt,
          orderNo: result.value.data?.orderNo,
          productSubsetId: result.value.data?.productSubsetId,
          profit: result.value.data?.profit,
        });

        if (res?.data?.success) {
          api.getUserDetails();
          let res = await api.orderTotal({ productId: grabId.value });
          if (res?.data?.success) {
            orderTotal.value = res.data.data;
          }
        }
      } finally {
        loading.value = false;
      }
    }

    function confirmVipUpgrade() {
      upgradeVisible.value = true;
    }

    function handleVipUpgrade() {
      if (+userDetail.value?.levelName?.slice(3) >= +upgradeModel.level) {
        message.info(t("common.message.noNeedUpgrade"));
        return;
      }
      let currentChooseID = vipPrivileges.value?.filter((item) => {
        return +item.userRank?.slice(3) === +upgradeModel.level;
      })[0]?.id;
      api.vipUpgrade({ id: currentChooseID }).then((res) => {
        if (res?.data?.success) {
          api.getUserDetails();
        }
      });
    }
    function closeUpgrade() {
      return false;
    }
    return {
      visible,
      handleGrab,
      vipPrivileges,
      vipPrivilege,
      currentPrivilege,
      formatMoney,
      formatPercentage,
      formatServerDateTime,
      userBalance,
      userData,
      upgradeModel,
      handleVipUpgrade,
      confirmVipUpgrade,
      closeUpgrade,
      upgradeVisible,
      handleAutomaticOrder,
      automaticVisible,
      tip,
      tips,
      result,
      t,
      userDetail,
      orderTotal,
      level,
      sysDataDictionaryWbObj,
      loading,
    };
  },
};
</script>

<style lang="scss" scoped>
.text-pre-line {
  white-space: pre-line;
}

.Sys_submit_btn.confirm {
  button {
    &[disabled] {
      background: #a0a0a0;
    }
  }
}

.Popup_bottom_vip ol {
  display: table;
  width: 100%;
  padding: 5px 0 20px;
  margin: 0;
  background-color: transparent;
}

.Popup_bottom_vip ol li {
  float: left;
  width: 33.33%;
  padding: 5px;
}

.Popup_bottom_vip ol li label {
  position: relative;
  display: table;
  width: 100%;
}

.Popup_bottom_vip ol li input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.Popup_bottom_vip ol li p {
  display: block;
  padding: 10px 0;
  font: bold 16px/16px Arial, Helvetica, sans-serif;
  color: #448d4b;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.Popup_bottom_vip ol li p span {
  display: block;
  width: 100%;
  padding: 0;
  margin: 5px 0 0;
  font-size: 14px;
  line-height: 16px;
}

.Popup_bottom_vip ol li input:checked + p {
  color: #f00;
  background: #fff0e9;
  border: 1px solid #d99d84;
}

.Popup_bottom_vip ol li input:disabled + p {
  color: #999;
  background: #fafafa;
}
</style>
